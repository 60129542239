/* eslint-disable */

import { connect } from 'react-redux';
import { PureComponent } from 'react';

import AsapDelivery from './AsapDelivery.componet';

/** @namespace Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({});

/** @namespace Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/AsapDelivery/Container */
export class AsapDeliveryContainer extends PureComponent {

    __construct(props) {
        super.__construct?.(props);
    }

    containerFunctions = {};

    containerProps() {
        const {
            product,
            is_details
        } = this.props;

        let isAsSoonasPossible = false;
        if(product && Object.keys(product).length > 0){
            const { attributes } = product;
            if(attributes &&  attributes?.is_available_express_home_delivery){
                if(attributes?.is_available_express_home_delivery?.attribute_value === "1"){
                    isAsSoonasPossible = true;
                }
            }
        }

        return {
            isAsSoonasPossible,
            is_details
        };
    }



    render() {
        return <AsapDelivery
            { ...this.containerFunctions }
            { ...this.containerProps() }
        />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsapDeliveryContainer);
